import apiClient from "./apiClient";
import timingModule from "./timingModule";
import { i18n } from "../shared/i18n"
import debounce from 'lodash.debounce'

import { createStore } from 'vuex'
import { ElectionChannelSubscription } from "@/channels/ElectionChannel";
import { ref } from "vue";
import { flattenOptions } from "../shared/contest_utilities";

// This feels real janky, but the subscription can't be in the actual store's state, because it updates itself
export const electionChannelSubscription = ref(null);

export default new createStore({
  strict: import.meta.env.MODE !== 'production',
  modules: {
    timing: timingModule
  },
  state () {
    return {
      readonly: false,
      // Fixed for election
      electionUrl: null,
      postsUrl: null,
      latestConfig: null,

      partnerBranding: {
        logo: null,
        link: null
      },

      organisationBranding: {
        name: null,
        logo: null
      },

      // This value will get updated when a
      // new election json is fetched from server
      election: null,
      slides: [],
      contests: [],
      votingRounds: [],
      votingRoundReports: [],
      posts: [],
      handRaiseOrchestrators: [],
      activeSlideId: null,
      locales: [],
      currentLocale: i18n.global.locale,
      chatUser: {
        id: null,
        type: null,
        name: null
      },

      activeHighlight: null,
      votingRound: null,
      visibleTab: 'Presentation',

      // Used for backend Live
      postToHighlight: null,
      postToAddToBallot: null,

      // Used for determining which way to animate slides
      slideOffset: 0,

      // Used to determine if the server is available
      lostConnectionToServer: false,

      lostConnectionToWebsocket: false,

      //
      voterCounts: {
        totalVoters: 0,
        activeVoters: 0,
        totalEligibles: 0,
        activeEligibles: 0,
        activeEligiblesOrVoted: 0,
        totalEligiblesWeight: 0,
        activeEligiblesWeight: 0,
        activeEligiblesOrVotedWeight: 0,
        voted: 0,
        votedWeight: 0
      },

      // Overall theme color
      theme: 'light',

      // Notifications
      toasts: [],
      toastId: 0
    }
  },
  getters:{
    activeSlide(state){
      if(state.slides === null) return null;
      return state.slides.find(slide => slide.id === state.activeSlideId)
    },
    firstAvailableLocale(state){
      return state.locales.includes(i18n.global.locale) ? i18n.global.locale : state.locales[0]
    },
    activeSlideVotingRound(state, getters){
      if (getters.activeSlide === null) return null

      const currentVotingRound = state.votingRounds.find(vr => vr.reference === getters.activeSlide.votingRoundReference);
      return currentVotingRound
    },
    contestsInActiveVotingRound(state, getters) {
      return state.contests.filter(
        contest => getters.activeSlideVotingRound.contestReferences.includes(contest.reference)
      );
    },
    activeHandRaiseOrchestrator(state, getters) {
      return state.handRaiseOrchestrators.find(
        orchestrator => orchestrator.votingRoundReference === getters.activeSlide.votingRoundReference
      )
    },
    handRaiseLiveResults(state, getters) {
      if (!getters.activeHandRaiseOrchestrator) return null;
      const showPercentage = getters.contestsInActiveVotingRound[0].resultPercentages

      // First populate the object
      let partialResult = new Object({
        not_voted: {
          showPercentage: showPercentage,
          reference: "not_voted",
          results: {
            count: 0,
          }
        },
      });

      if (getters.contestsInActiveVotingRound[0].blankOption !== "disabled") partialResult.blank = {
        reference: "blank",
        showPercentage: showPercentage && !getters.contestsInActiveVotingRound[0].disregardBlankVotes,
        results: {
          count: 0,
          percentage: 0,
        }
      }

      // We will only allow one contest per VR on handraise, so index 0 should do.
      flattenOptions(getters.contestsInActiveVotingRound[0].options).forEach(option => {
        partialResult[option.reference] = {
          showPercentage: showPercentage,
          reference: option.reference,
          results: {
            count: 0,
            percentage: 0,
          }
        }
      });

      // Then calculate and update the counts
      getters.activeHandRaiseOrchestrator?.handRaiseVotes?.forEach(vote => {
        if (!vote.optionReferences.length) {
          partialResult.blank.results.count += vote.voterWeight
        }
        vote.optionReferences.forEach(optRef => partialResult[optRef].results.count += vote.voterWeight)
      });

      // Calculate absolute number of people that hasn't voted
      const result = getters.handRaiseResultsByVoterName.reduce((voted, voter) => voted + voter.voted, 0)
      partialResult.not_voted.results.count = state.voterCounts.totalVoters - result

      // Calculate the percentage base
      const counts = Object.keys(partialResult).map(key => {
        switch (key) {
          case "not_voted": return 0;
          case "blank": return getters.contestsInActiveVotingRound[0].disregardBlankVotes ? 0 : partialResult.blank.results.count;
          default: return partialResult[key].results.count;
        }
      });

      const percentage_base = counts.reduce((initial, count) => initial + count, 0);

      // Finally calculate and update the percentages
      function round(value, decimals) {
        let rounder = Math.pow(10, decimals)
        return (Math.round(value * rounder) / rounder).toFixed(decimals)
      }

      function calculatePercentage(totalVotes, optionVotes) {
        let percentage = ((100 / totalVotes) * optionVotes) || 0

        return round(percentage, 1)
      }

      Object.keys(partialResult).forEach(key => {
        if (key !== "not_voted") partialResult[key].results.percentage =
          calculatePercentage(percentage_base, partialResult[key].results.count)
      })

      return partialResult
    },
    handRaiseResultsByVoterName(state, getters) {
      let processedVoters = []

      // Generate list of voters
      getters.activeHandRaiseOrchestrator.voters.forEach(voter => {
        processedVoters.push({
          ...voter,
          voted: false,
          optionReferences: null
        })
      })

      // Update voter statuses
      getters.activeHandRaiseOrchestrator.handRaiseVotes.forEach(vote => {
        processedVoters.map(voter => {
          if (vote.voterIdentifier === voter.identifier) {
            voter.voted = true
            voter.optionReferences = vote.optionReferences
            voter.updatedAt = vote.updatedAt
          }
        })
      })


      return processedVoters
    },
    handRaiseResultsByVoterVote(state, getters) {
      let optionsHash = {
        not_voted: {
          reference: 'not_voted',
          votes: [],
        }
      }

      if (getters.contestsInActiveVotingRound[0].blankOption !== "disabled") optionsHash.blank = {
        reference: 'blank',
        votes: [],
      }

      flattenOptions(getters.contestsInActiveVotingRound[0].options).forEach(
        (option, i) => optionsHash[option.reference] = {
          reference: option.reference,
          votes: [],
        }
      )

      getters.handRaiseResultsByVoterName.forEach(voter => {
        if (!voter.voted) {
          optionsHash.not_voted.votes.push(voter)
          return
        }
        if (!voter.optionReferences.length) {
          optionsHash.blank.votes.push(voter)
          return
        }
        voter.optionReferences.map(
          option => optionsHash[option].votes.push(voter)
        )
      })

      return optionsHash
    },
    forceFullSizeSlide(state, getters) {
      return !!getters.activeHandRaiseOrchestrator
        && getters.activeSlide.observerLayout === "all_visible"
        && getters.activeSlide.state === "open"
    },
  },
  mutations: {
    setPartnerBranding(state, partnerBranding){
      state.partnerBranding = partnerBranding
    },
    setOrganisationBranding(state, organisationBranding){
      state.organisationBranding = organisationBranding
    },
    setElectionUrl(state, { electionUrl }){
      state.electionUrl = electionUrl
      state.postsUrl = electionUrl.replace('/observe','')+'/posts'
    },
    setElection(state, election){
      state.election = election
    },
    setLocales(state, locales){
      state.locales = locales
    },
    setSlides(state, slides){
      state.slides = slides
    },
    setVotingRounds(state, votingRounds){
      state.votingRounds = votingRounds
    },
    setVotingRoundReports(state, votingRoundReports){
      state.votingRoundReports = votingRoundReports
    },
    setContests(state, contests){
      state.contests = contests
    },
    setHandRaiseOrchestrators(state, handRaiseOrchestrators){
      state.handRaiseOrchestrators = handRaiseOrchestrators
    },
    setHandRaiseLiveResults(state, handRaiseLiveResults){
      state.handRaiseLiveResults = handRaiseLiveResults
    },
    setPosts(state, posts){
      let unreadIds = state.posts.filter(post => post.unread === true).map(post => post.id)
      state.posts = posts
      state.posts.forEach(post => unreadIds.includes(post.id) ? post.unread = true : null)
    },
    setCounts(state, voterCounts){
      state.voterCounts = Object.assign(state.voterCounts, voterCounts)
    },
    setChatUser(state, chatUser){
      state.chatUser = chatUser
    },
    setPostToHighlight(state, post){
      if(post === null)
        return state.postToHighlight = null

      state.postToHighlight = Object.assign({}, post)
    },
    setVotingRound(state, vr){
      if(vr === null)
        return state.votingRound = null

      state.votingRound = Object.assign({}, vr)
    },
    setPostToAddToBallot(state, post){
      if(post === null)
        return state.postToAddToBallot = null

      state.postToAddToBallot = Object.assign({}, post)
    },
    setActiveHighlight(state, highlight){
      state.activeHighlight = highlight
    },
    setTheme(state, theme) {
      state.theme = theme
    },
    goToSlide(state, id){
      let newSlide = state.slides.find(slide => slide.id === id);
      if(!newSlide) return state.activeSlideId = null
      let oldSlide = state.slides.find(slide => slide.id === state.activeSlideId)
      state.slideOffset = oldSlide ? oldSlide.position - newSlide.position : newSlide.position;
      state.activeSlideId = id
    },
    createItem(state, {path, item}){
      switch(path.type){
        case 'slide':
          state.slides.push(item)
          break;
        case 'option':
          let parentItem = state.contests.find(contest => contest.id === path.contest_id)

          let ancestryArray = path.ancestry?.split('/')?.filter(s => s !== '')?.map(s => { return parseInt(s) })

          if (!ancestryArray.length) {
            state.contests.find(contest => contest.id === path.contest_id).options.push(item)
          } else {
            let newParent = parentItem
            ancestryArray.forEach(id => {
              if (newParent.options) {
                newParent = newParent.options.find(option => option.id === id)
              } else {
                newParent = newParent.children.find(option => option.id === id)
              }
            })

            newParent.children.push(item)
          }

          let duplicate = state.contests.slice() // Vue wont register an update without this
          this.commit("setContests", duplicate)
          break;
        case 'post':
          if(state.visibleTab === 'Comment' && item.type === 'Comment') item.unread = false
          state.posts.push(item)
          break;
        case 'votingRound':
          state.votingRounds.push(item)
          break;
        case 'votingRoundReport':
          state.votingRoundReports.push(item)
          break;
        case 'handRaiseOrchestrator':
          state.handRaiseOrchestrators.push(item)
          break;
        case 'handRaiseVote':
          state.handRaiseOrchestrators
            .find(handRaiseOrchestrator => handRaiseOrchestrator.id === path.hand_raise_orchestrator_id)
            ?.handRaiseVotes?.push(item)
          break;
      }
    },
    updateItem(state, {path, changes}){
      let storeItem = null
      let parentItem = null
      switch(path.type){
        case 'slide':
          storeItem = state.slides.find(slide => slide.id === path.id)
          storeItem = Object.assign(storeItem, changes)
          if(changes.position !== undefined) {
            state.slides.sort((a, b) => a.position-b.position)
          }
          break;
        case 'option':
          parentItem = state.contests.find(contest => contest.id === path.contest_id)

          let ancestryArray = path.ancestry?.split('/')?.filter(s => s !== '')?.map(s => { return parseInt(s) })

          if (!ancestryArray.length) {
            storeItem = state.contests.find(contest => contest.id === path.contest_id).options.find(option => option.id === path.id)
            Object.assign(storeItem, changes)
            if (changes.position !== undefined) {
              state.contests.find(contest => contest.id === path.contest_id).options.sort((a, b) => a.position - b.position)
            }
          } else {
            let newParent = parentItem
            ancestryArray.forEach(id => {
              if (newParent.options) {
                newParent = newParent.options.find(option => option.id === id)
              } else {
                newParent = newParent.children.find(option => option.id === id)
              }
            })

            storeItem = newParent.children.find(option => option.id = path.id)
            Object.assign(storeItem, changes)
            if (changes.position !== undefined) {
              newParent.children.sort((a, b) => a.position - b.position)
            }
          }

          let duplicate = state.contests.slice() // Vue wont register an update without this
          this.commit("setContests", duplicate)

          break;
        case 'contest':
          storeItem = state.contests.find(contest => contest.id === path.id)
          storeItem = Object.assign(storeItem, changes)
          break;
        case 'post':
          storeItem = state.posts.find(post => post.id === path.id)
          storeItem = Object.assign(storeItem, changes)
          break;
        case 'votingRound':
          storeItem = state.votingRounds.find(votingRound => votingRound.id === path.id)
          if (storeItem) storeItem = Object.assign(storeItem, changes)
          break;
        case 'votingRoundReport':
          storeItem = state.votingRoundReports.find(votingRoundReport => votingRoundReport.id === path.id)
          storeItem = Object.assign(storeItem, changes)
          break;
        case 'highlight':
          state.activeHighlight = changes
          break;
        case 'handRaiseOrchestrator':
          storeItem = state.handRaiseOrchestrators.find(handRaiseOrchestrator => handRaiseOrchestrator.id === path.id)
          storeItem = Object.assign(storeItem, changes)
          break;
        case 'handRaiseVote':
          parentItem = state.handRaiseOrchestrators
            .find(handRaiseOrchestrator => handRaiseOrchestrator.id === path.hand_raise_orchestrator_id)
          storeItem = parentItem.handRaiseVotes.find(vote => vote.id === path.id)
          storeItem = Object.assign(storeItem, changes)
          break;
      }
    },
    deleteItem(state, path){
      let itemIndex = null
      let parentItem = null
      switch(path.type){
        case 'slide':
          itemIndex = state.slides.findIndex(slide => slide.id === path.id)
          if( ~ itemIndex ) state.slides.splice(itemIndex, 1)
          break
        case 'option':
          parentItem = state.contests.find(contest => contest.id === path.contest_id)
          if( ! parentItem ) break

          let ancestryArray = path.ancestry?.split('/')?.filter(s => s !== '')?.map(s => { return parseInt(s) })

          if (!ancestryArray.length) {
            itemIndex = parentItem.options.findIndex(option => option.id === path.id)
            if (~itemIndex) parentItem.options.splice(itemIndex, 1)
          } else {
            let newParent = parentItem
            ancestryArray.forEach(id => {
              if (newParent.options) {
                newParent = newParent.options.find(option => option.id === id)
              } else {
                newParent = newParent.children.find(option => option.id === id)
              }
            })

            newParent.children.findIndex(option => option.id === path.id)
            if (~itemIndex) newParent.children.splice(itemIndex, 1)
          }

          let duplicate = state.contests.slice() // Vue wont register an update without this
          this.commit("setContests", duplicate)
          break
        case 'post':
          itemIndex = state.posts.findIndex(post => post.id === path.id)
          if( ~ itemIndex ) state.posts.splice(itemIndex, 1)
          break
        case 'votingRound':
          itemIndex = state.votingRounds.findIndex(votingRound => votingRound.id === path.id)
          if( ~ itemIndex ) state.votingRounds.splice(itemIndex, 1)
          break
        case 'votingRoundReport':
          itemIndex = state.votingRoundReports.findIndex(votingRoundReport => votingRoundReport.id === path.id)
          if( ~ itemIndex ) state.votingRoundReports.splice(itemIndex, 1)
          break
        case 'handRaiseOrchestrator':
          itemIndex = state.handRaiseOrchestrators
            .findIndex(handRaiseOrchestrator => handRaiseOrchestrator.id === path.id)
          if( ~ itemIndex) state.handRaiseOrchestrators.splice(itemIndex, 1)
          break;
        case 'handRaiseVote':
          parentItem = state.handRaiseOrchestrators
            .find(handRaiseOrchestrator => handRaiseOrchestrator.id === path.hand_raise_orchestrator_id)
          itemIndex = parentItem.handRaiseVotes.findIndex(vote => vote.id === path.id)
          if( ~ itemIndex) parentItem.handRaiseVotes.splice(itemIndex, 1)
          break;
      }
    },
    markAllPostsRead(state, type){
      let posts = state.posts.filter(p => p.type === type)
      posts.forEach(p => p.unread = false)
    },
    markPostRead(state, id){
      let post = state.posts.find(post => post.id === id)
      if(post) post.unread = false
    },
    setBallotState(state, {ballotId, ballotState}){
      let ballot = state.slides[state.slides.findIndex(slide => slide.id === ballotId)]
      ballot.state = ballotState
    },
    setResult(state, {ballotId, result}){
      let ballot = state.slides[state.slides.findIndex(slide => slide.id === ballotId)]
      ballot.result = result
    },
    resetBallot(state, ballotId){
      let ballot = state.slides[state.slides.findIndex(slide => slide.id === ballotId)]
      if(state.voting){
        let votedOn = state.voting.currentVoter.votedOn
        votedOn.splice(votedOn.indexOf(ballotId), 1)
      }
      ballot.result = null
      ballot.voteCount = 0
      ballot.votesCombinedWeight = 0
    },
    closeBallot(state, {ballotId, time}){
      let ballot = state.slides.find(slide => slide.id === state.activeSlideId)
      if(ballot.id !== ballotId) return

      ballot.disableAt = time
    },
    setProgress(state, { ballotId, progress }){
      let ballot = state.slides.find(slide => slide.id === state.activeSlideId)
      if( ballot?.id !== ballotId )
        return

      ballot = Object.assign(ballot, progress)
    },
    setVisibleTab(state, tab){
      state.visibleTab = tab
    },
    connectionFailed(state) {
      state.lostConnectionToServer = true
    },
    connectionToWebsocketFailed(state) {
      state.lostConnectionToWebsocket = true
    },
    connectionToWebsocketRestored(state) {
      state.lostConnectionToWebsocket = false
    },
    connectionSucceeded(state){
      state.lostConnectionToServer = false
    },
    setLocale(state, locale){
      if(i18n.global.availableLocales.includes(locale)){
        state.currentLocale = locale
        i18n.global.locale = locale

        document.getElementsByTagName('html')[0].lang = locale;
        const rtlLanguages = ['ar', 'dv', 'fa', 'ha', 'he', 'ks', 'ku', 'ps', 'ur', 'yi'];
        rtlLanguages.includes(locale)
          ? (document.getElementsByTagName('html')[0].dir = 'rtl')
          : (document.getElementsByTagName('html')[0].dir = 'ltr');
        document.title = state.election.title[state.currentLocale];
      }
    },
    showToast(state, {header, body, classes, duration = null}){
      state.toastId += 1
      let index = state.toasts.findIndex(toast => toast.id === state.toastId)
      if(index !== -1) state.toasts.splice(index, 1)
      let toast = {
        id: state.toastId,
        header,
        body,
        classes
      }
      state.toasts.push(toast)
      if(duration){
        setTimeout(() => { this.commit('hideToast', toast) }, duration*1000)
      }
    },
    hideToast(state, toast){
      let index = state.toasts.indexOf(toast)
      if(index !== -1)
        state.toasts.splice(index, 1)
    },
    setReadonly(state, readonly){
      state.readonly = readonly
    },
    updateLatestConfig(state, latestConfig){
      state.latestConfig = latestConfig
    },
  },
  actions: {
    subscribeToElectionChannel({state, dispatch, commit}, live=false) {
      electionChannelSubscription.value = new ElectionChannelSubscription(
        {electionId: state.election.id},
        {
          connected() {
            dispatch("updateStatus");
          },
          received(data) {
            switch (data.type) {
              case 'goto':
                commit("goToSlide", data.id);
                break;
              case 'create_item':
                commit("createItem", {path: data.path, item: data.item})
                break;
              case 'update_item':
                if (data.path.type === "votingRoundReport" && (data.changes.publishedAt || live)) {
                  if (live) {
                    apiClient.get(`../voting_rounds/${data.path.voting_round_id}/voting_round_reports/${data.path.id}.json`)
                      .then(res => {
                        commit("updateItem", {path: data.path, changes: res.data})
                      })
                  } else {
                    dispatch("fetchResult", data.path);
                  }
                } else {
                  commit("updateItem", {path: data.path, changes: data.changes})
                }
                break;
              case 'delete_item':
                commit("deleteItem", data.path)
                break;
              case 'state_change':
                commit("setBallotState", {ballotId: data.ballot_id, ballotState: data.state});
                break;
              case 'progress':
                commit("setProgress", {ballotId: data.ballot_id, progress: data.progress})
                break
              case 'result_ready':
                if (live) dispatch("fetchLiveResult", data.ballot_id);
                break;
              case 'reset_ballot':
                commit("resetBallot", data.ballot_id)
                commit("voting/setRedoVote", false)
                break
              case 'update':
                dispatch("updatePosts");
                dispatch("updateStatus", live);
                break;
              case 'update_counts':
                commit("setCounts", data.voterCounts)
                if (live) dispatch("ensureLastUpdateCounts", data.force)
                break
              default:
                console.debug(`received unknown message ${data.type}; updating election`)
                dispatch("updateStatus", live);
                break;
            }
          }
        });
    },
    updateStatus({commit, state, dispatch}, live = false){
      // app#status requires a voter session, observer#status does not, either can be accessed from this action
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      let url = live ? `${state.electionUrl}/live_status` : `${state.electionUrl}/status`
      return apiClient.get(url, config).then(res => {
        let { election, slides, votingRounds, votingRoundReports, contests, voter, activeSlide, voterCounts, activeHighlight } = res.data

        if(state.voting) commit("voting/updateVoter", voter)

        commit('setElection', election)
        if(activeSlide) {
          let activeIndex = slides.findIndex(slide => slide.id === activeSlide.id)
          slides[activeIndex] = { ...slides[activeIndex], ...activeSlide}
        }

        commit('setSlides', slides)
        commit('setContests', contests)
        commit('setVotingRounds', votingRounds)
        commit('setVotingRoundReports', votingRoundReports)
        commit('setCounts', voterCounts)
        commit('setActiveHighlight', activeHighlight)

        const includesHandraise = slides.some(slide => slide.handRaise === true)
        if (includesHandraise) {
          apiClient.get(`${state.electionUrl}/hand_raise`).then(hand_res => {
            let { handRaiseOrchestrators } = hand_res.data
            commit('setHandRaiseOrchestrators', handRaiseOrchestrators)
          })
        }

        if(activeSlide)
          commit('goToSlide', activeSlide.id)
        else
          commit('goToSlide', null)

        console.log('election updated')
      })
    },
    updatePosts({commit, state}){
      // app#status requires a voter session, observer#status does not, either can be accessed from this action
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.get(`${state.postsUrl}`, config).then(res => {
        let {posts, chatUser} = res.data

        commit('setPosts', posts)
        commit('setChatUser', chatUser)

        console.log('posts updated')
      })
    },
    submitPost({dispatch, state}, post){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.post(state.postsUrl, post, config)
    },
    submitHighlight({dispatch, state}, highlight){
      if(state.voting)
        return

      return apiClient.post(state.electionUrl.replace('/observe','')+"/highlight", highlight)
    },
    editPost({dispatch, state}, post){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.put(state.postsUrl+"/"+post.id, post, config)
    },
    deletePost({dispatch, state}, postId){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.delete(state.postsUrl+"/"+postId, config)
    },
    restorePost({dispatch, state}, postId){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.post(state.postsUrl+"/"+postId+"/restore", {},config)
    },
    fetchResult({commit,state}, path){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid,
          voting_round_report_id: path.id
        }
      } : { params: {voting_round_report_id: path.id} };
      apiClient.get(state.electionUrl+"/result", config).then(res => {
        if(res.status === 200) {
          commit('updateItem', {path: path, changes: res.data})
        }
      })
    },
    fetchLiveResult({commit,state}, ballotId){
      apiClient.get(state.electionUrl+"/live_result", {params: {ballot_id: ballotId}}).then(res => {
        if(res.status === 200) {
          commit('setResult', {ballotId: ballotId, result: res.data})
        }
      })
    },
    checkConnection({commit, state}){
      apiClient.get(state.electionUrl, {timeout: 5000}).then(_ =>{
        commit('connectionSucceeded') // we got a response from the server, so not gone anymore
      }).catch(arg =>{
        if(arg.response) {
          commit('connectionSucceeded')  // we got an error response, which means we could make a connection
        }
      })
    },
    checkWebsocketConnection({commit, state}) {
      if (!electionChannelSubscription.value) return;

      let isDisconnected = electionChannelSubscription.value.subscription.consumer.connection.disconnected
      if (state.lostConnectionToWebsocket && !isDisconnected) {
        commit('connectionToWebsocketRestored')
      } else if (!state.lostConnectionToWebsocket && isDisconnected) {
        commit('connectionToWebsocketFailed')
      }
    },
    async fetchLatestConfig({commit,state}){
      apiClient.get(`${state.election.boardUrl}/configuration/latest_config`).then(res => {
        commit('updateLatestConfig', res.data)
      })
    },

    // Asks the server to force broadcast update counts.
    // This is done to ensure that the last count event is sent out.
    // Otherwise it might have been suppressed by the throttler functionality.
    // If the `force` parameter is set to true, it means that the event was emitted by
    // this method, and an update should be prevented to not create an everlasting loop.
    ensureLastUpdateCounts: debounce(({state}, force) => {
      if( force ) return;
      apiClient.put(`${state.electionUrl}/ensure_update_counts`)
    }, 3000)
  }
})
