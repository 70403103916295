import consumer from "./consumer";
import {UniqueChecker} from "../lib/UniqueChecker";
import {Subscription} from "@rails/actioncable";

export class ElectionChannelSubscription {
  public subscription: Subscription;
  private uniqueChecker: UniqueChecker<string>;


  constructor({electionId}, { received, connected = undefined, disconnected = undefined }: {
  received: ((data: Message) => void);
  connected?: ()=>void;
    disconnected?: ()=>void;
}) {
    this.uniqueChecker = new UniqueChecker<string>();
    this.subscription = consumer.subscriptions.create(
      {
        channel: "ElectionChannel",
        election_id: electionId,
      },
      {
        received: (data: Message) => {
          if (this.uniqueChecker.wasSeen(data.uuid))
            return console.debug("DUPLICATE MESSAGE RECEIVED: ", data);
          received(data);
        },
        connected() {
          console.debug("ElectionChannel connected")
        },
        disconnected() {
          console.debug("ElectionChannel disconnected")
        },
      }
    );
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }
}

// Types

interface DataMessage {
  type: string;
  uuid: string;
}

interface GotoMessage extends DataMessage {
  type: "goto";
  id: number;
}

interface CreateItemMessage extends DataMessage {
  type: "create_item";
  path: { id: number, type: string } & Record<string, any>;
  item: any;
}

interface UpdateItemMessage extends DataMessage {
  type: "update_item";
  path: { id: number, type: string } & Record<string, any>;
  changes: Record<string, any>;
}

interface DeleteItemMessage extends DataMessage {
  type: "delete_item";
  path: { id: number, type: string } & Record<string, any>;
}

// This should just be a update_item message instead
interface BallotStateChangeMessage extends DataMessage {
  type: "state_change";
  ballot_id: number;
  state: "open" | "closed" | "countdown" | string;
}

interface ProgressMessage extends DataMessage  {
  type: "progress";
  ballot_id: number;
  progress: {
    voteCount: number;
    votesCombinedWeight: number;
  }
}

interface ResetBallotMessage extends DataMessage  {
  type: "reset_ballot";
  ballot_id: number;
}
interface UpdateMessage extends DataMessage  {
  type: "update";
}
interface UpdateCountsMessage extends DataMessage  {
  type: "update_counts";
  voterCounts: Record<string, number>;
  force?: boolean;
}

interface ResultReadyMessage extends DataMessage {
  type: "result_ready";
  ballot_id: number;
  publicised: boolean;
}

type Message = GotoMessage | CreateItemMessage | UpdateItemMessage | DeleteItemMessage | BallotStateChangeMessage | ProgressMessage | ResetBallotMessage | UpdateMessage | UpdateCountsMessage | ResultReadyMessage | DataMessage;
